import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import {
	defineAsyncComponent
} from "vue"

const index = (() => import('@/views/index.vue'))

const routes = [{
		path: '/',
		name: 'index',
		component: index
	},
];


const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
